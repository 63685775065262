import React from 'react';
import styled from 'styled-components';
import Separator from '../../components/Separator/Separator';

const Start = () => {
  return (
    <StartWrapper>
      <h1>HEJ! <H1Extension className='h2'>VI ÄR REACTIVE PIXELS</H1Extension></h1>
      <p>... ett enmansföretag inom IT med mig, Emil, som konsult-resurs.</p>
      <Text>
        <Separator />
        <h2>FOKUS</h2>
        <p>Web i alla dess former där vi har lång erfarenhet av webbutveckling i modern teknik och olika ramverk. Några specialiteter är frontend, användarvänlighet och tillgänglighet. Får vi dessutom jobba tillsammans med en grafisk designer kan vi göra riktigt bra saker.</p>
        <Separator />
        <h2>TILLGÄNGLIGHET</h2>
        <p>Just nu är vi fullbokade. Viss tid kan dock finnas för mindre tidskrävande uppdrag.<br/> Oavsett behov, kontakta oss gärna så tar vi det därifrån 😊</p>
        <p>/Emil</p>
      </Text>
    </StartWrapper>
  );
}

const StartWrapper = styled.div`
  position: relative;
  h2 {
    margin-bottom: 0;
  }
`

const H1Extension = styled.span`
  display: block;
  margin-top: 20px;
`;

const Text = styled.div`
  max-width: 740px;
  margin: 0 auto;
  text-align: left;
`

export default Start;
