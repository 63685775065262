import styled from "styled-components";

export const MenuWrapper = styled.nav`
  position: relative;
  display: flex;
  margin: 20px 0;

  ul {
    position: relative;
    display: flex;
    padding: 0;
    margin: 0;
  }
`

export const MenuItem = styled.li`
  position: relative;
  list-style: none;
  padding: 15px 30px;
  outline: 1px solid transparent;
  font-size: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #999999;
  transition: color 0.2s ease-out, border-bottom 0.2s ease-out, outline-color 0.2s ease-out;

  &:not(.active):hover,
  &:not(.active):focus {
    color: #ffffff;
    outline: 1px solid #ffffff;
    cursor: pointer;
  }

  &.active {
    color: #ffffff;
    cursor: pointer;

    & > span::after {
      width: 100%;
    }
  }

  & > span {
    position: relative;
    display: block;

    &::after {
      content: '';
      position: absolute;
      margin: 0 auto;
      height: 1px;
      width: 0;
      bottom: -8px;
      left: 0;
      right: 0;
      background-color: #ffffff;
      transition: width 0.2s ease-out;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 8px 12px;
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    padding: 6px;
    letter-spacing: 0;

    &:not(.active):hover,
    &:not(.active):focus {
      outline: 1px solid transparent;
    }
  }
`
