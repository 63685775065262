import styled from "styled-components";

export const FooterWrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  padding: 60px 0;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding: 40px 0;
  }
`
export const FooterCopyRight = styled.div`
  @media only screen and (max-width: 600px) {
    order: 3;
  }
`
export const FooterSocial = styled.div`
  margin: 0 80px;

  @media only screen and (max-width: 600px) {
    order: 2;
    margin: 20px 0;
  }
`
export const FooterContact = styled.div`
  @media only screen and (max-width: 600px) {
    order: 1;
  }
  & > a {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      margin-right: 5px;
    }
  }
`
