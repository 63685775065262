import React, { useState } from "react"
import { Pages } from "../App"

type AppState = {
  activePage?: Pages,
  setActivePage: (newPage?: Pages) => void
}

const defaultState: AppState = {
  activePage: undefined,
  setActivePage: (newPage?: Pages) => {}
}

export const AppContext = React.createContext(defaultState)

type AppContextProviderProps = {
  children: any
}

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const setActivePage = (newPage?: Pages) => {
    setAppState(prevState => ({ ...prevState, activePage: newPage }))
  }

  const [appState, setAppState] = useState<AppState>({
    activePage: undefined,
    setActivePage
  })

  return <AppContext.Provider value={appState}>
    {children}
  </AppContext.Provider>
}

export default AppContextProvider