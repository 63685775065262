import React from 'react';
import styled from 'styled-components';
import playstore from '../../assets/tiles-story/playstore-icon.png';

const TilesStoryDataDeletionPage = () => {
  return (
    <Wrapper>
      <Head>
        <img alt='Tiles Story app-ikon' width={140} src={playstore} />
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <H1>Tiles Story - Data Deletion Instructions</H1>
        </div>
      </Head>
      <DataDeletion>
        <p>If you want to delete your personal data on Tiles Story, follow these instructions:</p>
        <ol>
          <li>In the Tiles Story mobile app, go to settings and then integrity. Here you can request your data to be deleted.</li>
          <li>If you are logging in with Facebook: Go to Your Facebook Account's "Settings & Privacy". Click "Settings". Then, go to "Security & Login", then go to "Apps and Websites" and you will see all of your Apps activities. Find Tiles Story in the list of apps. Click "Remove" button.</li>
          <li>If you are having problems or questions, please send an e-mail to info@reactivepixels.se and we will assist you.</li>
        </ol>

        <p>Comment:</p>
        <p>Doing this means that you cannot use all of Tiles Story's features anymore.</p>
      </DataDeletion>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  font-family: satoshi-regular;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  img {
    margin-right: 30px;
    border-radius: 10px;
  }
`

const H1 = styled.h1`
  font-family: gasoek-one;
  font-size: 56px;
  line-height: 56px;
  margin: 0;
  color: #fff;
  font-weight: 400;
  max-width: 800px;

  @media only screen and (max-width: 1020px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

const DataDeletion = styled.div`
  margin: 40px auto 60px auto;
  max-width: 1000px;

  p,
  li {
    text-align: left;
    color: #999999;
  }

  h2 {
    margin-top: 60px;
  }
`

export default TilesStoryDataDeletionPage;
