import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import logo from './ReactivePixels_light-text.png';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import MainContent from './components/MainContent/MainContent';
import Stars from './components/Stars/Stars';
import styled from 'styled-components';
import CookieBanner from './components/CookieBanner/CookieBanner';
import { AppContext } from './context/AppContext';

export enum Pages {
  Start = '/',
  Services = '/services',
  Contact = '/contact',
  Cookies = '/cookies',
  TilesStoryLanding = '/tiles-story',
  TilesStoryPrivacy = '/tiles-story/privacy-policy',
  TilesStoryDataDeletion = '/tiles-story/data-deletion',
}

type AppProps = {
  welcome?: boolean
}

const App = (props: AppProps) => {
  const [render, setRender] = useState<boolean>(false);
  const navigate = useNavigate()
  const appContext = useContext(AppContext)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const togglePage = (newPage: Pages) => {
    appContext?.setActivePage(newPage)
    document.querySelectorAll('.stars').forEach(e => e.classList.remove('visible'));
    setTimeout(() => {
      navigate(newPage)
      scrollToTop()
    }, 300);
  }

  useEffect(() => {
    const browserPath = window?.location?.pathname
    if ([`${Pages.Start}`,`${Pages.Services}`,`${Pages.Contact}`].includes(browserPath)) {
      appContext?.setActivePage(browserPath as Pages)
    } else {
      appContext?.setActivePage(undefined)
    }
    scrollToTop()
    setTimeout(() => {
      setRender(true)
    }, 800);
  }, [])

  return (
    <div className="App">
      {!render && props.welcome ? (
        <WelcomeAnimation>
          <img src={logo} className="header-logo" alt="Reactive Pixels logotyp" />
        </WelcomeAnimation>
      ) : (
        <>
          <Stars />
          <CookieBanner />
          <Header togglePage={togglePage} />
          <MainContent />
          <Footer />
        </>
      )}
    </div>
  );
}

const WelcomeAnimation = styled.div`
  animation: animateWelcome 0.8s;
  animation-fill-mode: forwards;

  @keyframes animateWelcome {
    0% {
      opacity: 0;
      margin-top: calc(200px);
    }
    100% {
      opacity: 1;
      margin-top: calc(60px);
    }
  }
`

export default App;
