import styled from "styled-components";

export const SeparatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;

  @media only screen and (max-width: 600px) {
    margin: 20px 0;
  }
`

export const SeparatorBox = styled.div`
  height: 10px;
  width: 10px;
  background-color: #ffffff;
  margin: 0 20px;

  @media only screen and (max-width: 600px) {
    height: 6px;
    width: 6px;
    margin: 0 15px;
  }
`

export const SeparatorLine = styled.div`
  height: 1px;
  width: 45px;
  background-color: #ffffff;

  @media only screen and (max-width: 600px) {
    width: 30px;
  }
`
