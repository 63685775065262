import React, { useContext } from 'react';
import { Pages } from '../../App';
import { AppContext } from '../../context/AppContext';
import { MenuItem, MenuWrapper } from './Menu.style';

type MenuProps = {
  togglePage?: (page: Pages) => void;
}
const Menu = (props: MenuProps) => {
  const appContext = useContext(AppContext)
  return (
    <MenuWrapper>
      <ul>
        <MenuItem
          className={appContext?.activePage === Pages.Start ? 'active' : ''}
          onClick={() => props.togglePage?.(Pages.Start)}
          onKeyDown={(e) => e.key === 'Enter' && props.togglePage?.(Pages.Start)}
          tabIndex={0}
        >
          <span>Start</span>
        </MenuItem>
        <MenuItem
          className={appContext?.activePage === Pages.Services ? 'active' : ''}
          onClick={() => props.togglePage?.(Pages.Services)}
          onKeyDown={(e) => e.key === 'Enter' && props.togglePage?.(Pages.Services)}
          tabIndex={0}
        >
          <span>Tjänster</span>
        </MenuItem>
        <MenuItem
          className={appContext?.activePage === Pages.Contact ? 'active' : ''}
          onClick={() => props.togglePage?.(Pages.Contact)}
          onKeyDown={(e) => e.key === 'Enter' && props.togglePage?.(Pages.Contact)}
          tabIndex={0}
        >
          <span>Kontakt</span>
        </MenuItem>
      </ul>
    </MenuWrapper>
  );
}

export default Menu;
