import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../context/AppContext'

const CookiePolicy = () => {
  const appContext = useContext(AppContext)

  useEffect(() => {
    appContext?.setActivePage(undefined)
  }, [])

  return <CookiePolicyWrapper>
    <Text>
      <h1>Cookie-policy</h1>
      <h2>1. Vad är en cookie?</h2>
      <p>På reactivepixels.se används cookies. En cookie är en liten datafil som lagras på din dator för att en webbsida ska känna igen dig nästa gång du kommer tillbaka. En cookie-fil är passiv och innehåller endast text. Den kan inte sprida skadliga program eller datavirus.</p>
      <p>Webbsidan använder två typer av cookies, ”vanliga” cookies och sessionscookies. Vanliga cookies sparas på din dator under en längre tid medan sessionscookies försvinner så snart du stänger din webbläsare.</p>

      <h2>2. Vad används cookies till på reactivepixels.se?</h2>
      <p>På reactivepixels.se används cookies för att:</p>
      <ul className='p'>
        <li>Analysera hur sajten används för att kunna göra förändringar som gynnar läsbarhet och förbättrar layout samt innehåll.</li>
        <li>Hålla koll på antalet användare och trafik för respektive sida som besöks, samt siten som helhet. Att se hur många som besöker siten och vart i världen dessa besök kommer ifrån hjälper utvecklingen av webbplatsen.</li>
      </ul>

      <h2>3. Vill du undvika cookies?</h2>
      <p>Att neka cookies görs via din webbläsares inställningar. Väljer du att inte acceptera cookies på reactivepixels.se kan du fortfarande ta del av allt innehåll. För mer information om hur du nekar användningen av cookies se: www.pts.se/cookies.</p>

      <h2>4. Tredjeparts-cookies</h2>
      <p>Idag används inga tredjeparts-cookies på reactivepixels.se. För att läsa mer om tredjepartscookies, besök: www.pts.se/cookies.</p>
    </Text>
  </CookiePolicyWrapper>
}

const CookiePolicyWrapper = styled.div`
  position: relative;
`

const Text = styled.div`
  max-width: 740px;
  margin: 0 auto;
  text-align: left;

  h2 {
    margin-bottom: 0;
  }
`

export default CookiePolicy