import styled from "styled-components"

export const HeaderWrapper = styled.header`
  position: relative;
  padding: 60px 60px 10px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  transform: translateZ(1px);
  z-index: 1;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  @media only screen and (max-width: 900px) {
    padding: 30px 30px 0 30px;
  }

  @media only screen and (max-width: 600px) {
    padding: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: sticky;
    top: 0;
    left: 0;
    right: 0;

    &.scrolled {
      background-color: #05080bee;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 1);
    }
  }
`

export const HeaderLogoLink = styled.div`
  cursor: pointer;
`

export const HeaderLogo = styled.img`
  width: calc(2.4375 * 224px);
  height: 224px;
  pointer-events: none;

  @media only screen and (max-width: 1600px) {
    width: calc(2.4375 * 168px);
    height: 168px;
  }

  @media only screen and (max-width: 900px) {
    width: calc(2.4375 * 112px);
    height: 112px;
  }

  @media only screen and (max-width: 600px) {
    width: calc(2.4375 * 56px);
    height: 56px;
  }

  @media only screen and (max-width: 450px) {
    width: calc(2.4375 * 42px);
    height: 42px;
  }
`
