import React from 'react';
import { TfiEmail, TfiMobile } from 'react-icons/tfi';
import styled from 'styled-components';
import Separator from '../../components/Separator/Separator';

const Contact = () => {
  return (
    <ContactWrapper>
      <h1>KONTAKT</h1>
      <p>Oavsett dina behov, kontakta oss gärna så ser vi hur vi kan hjälpa till!</p>
      <Separator />
      <ContactAlternatives>
        <Alternative>
          <h2>E-POST</h2>
          <a href='mailto:info@reactivepixels.se' aria-label='Skicka e-post till Reactive Pixels'><TfiEmail size={20} color="#ffffff"/> info@reactivepixels.se</a>
        </Alternative>
        <Alternative>
          <h2>TELEFON</h2>
          <a href='tel:+46762710514' aria-label='Ring till Reactive Pixels'><TfiMobile size={20} color="#ffffff"/> +46 76 271 05 14</a>
        </Alternative>
      </ContactAlternatives>
    </ContactWrapper>
  );
}

const ContactWrapper = styled.div`
  position: relative;
`
const ContactAlternatives = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const Alternative = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    margin-top: 30px;
    align-items: center;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`

export default Contact;
