import styled from "styled-components"

export const BannerWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 64px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: #111111;
  z-index: 1;
  transform: translateZ(2px);

  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }

  @media only screen and (max-width: 600px) {
    height: 80px;
  }
`;

export const BannerContent = styled.div`
  position: relative;
  display: flex;
  max-width: 740px;

  @media only screen and (max-width: 600px) {
    padding: 10px;
  }
`

export const BannerText = styled.span`
  display: block;
  height: 100%;
  font-size: 16px;
  text-align: left;
`

export const PolicyLink = styled.span`
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
`

export const BannerButton = styled.button`
  background-color: transparent;
  border: none;
  margin-left: 60px;
  cursor: pointer;
  transition: transform 0.15s ease-in;

  &:hover,
  &:focus {
    transform: scale(1.3);
  }

  @media only screen and (max-width: 600px) {
    margin-left: 15px;
  }
`
