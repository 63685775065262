import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Start from './pages/Start/Start';
import CookiePolicy from './pages/CookiePolicy/CookiePolicy';
import AppContextProvider from './context/AppContext';
import TilesStoryLandingPage from './pages/TilesStory/TilesStoryLandingPage';
import TilesStoryPrivacyPage from './pages/TilesStory/TilesStoryPrivacyPage';
import TilesStoryDataDeletionPage from './pages/TilesStory/TilesStoryDataDeletionPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>404 Not Found</div>,
    children: [
      {
        path: '',
        element: <Start />,
      },
      {
        path: 'services',
        element: <Services />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'cookies',
        element: <CookiePolicy />,
      },
      {
        path: 'tiles-story',
        element: <TilesStoryLandingPage />,
      },
      {
        path: 'tiles-story/privacy-policy',
        element: <TilesStoryPrivacyPage />,
      },
      {
        path: 'tiles-story/data-deletion',
        element: <TilesStoryDataDeletionPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <RouterProvider router={router} />
    </AppContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
