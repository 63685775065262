import React, { useContext } from 'react';
import styled from 'styled-components';
import dailyword from '../../assets/tiles-story/dailyword.png';
import level5 from '../../assets/tiles-story/level5.png';
import lobby from '../../assets/tiles-story/lobby.png';
import playstore from '../../assets/tiles-story/playstore-icon.png';
import appstore from '../../assets/tiles-story/appstore-icon.svg';
import { useNavigate } from 'react-router-dom';
import { Pages } from '../../App';
import { AppContext } from '../../context/AppContext';

const downloadUrlGoogle = "https://play.google.com/store/apps/details?id=se.reactivepixels.tilesstory&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
const badgeUrlGoogle = "https://play.google.com/intl/en_us/badges/static/images/badges/sv_badge_web_generic.png"
const downloadUrlApple = "https://apps.apple.com/se/app/tiles-story/id6473028615?platform=iphone"

const TilesStoryLandingPage = () => {
  const navigate = useNavigate()
  const appContext = useContext(AppContext)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const togglePage = (newPage: Pages) => {
    appContext?.setActivePage(newPage)
    document.querySelectorAll('.stars').forEach(e => e.classList.remove('visible'));
    setTimeout(() => {
      navigate(newPage)
      scrollToTop()
    }, 300);
  }

  return (
    <Wrapper>
      <Head>
        <img alt='Tiles Story app-ikon' width={140} src={playstore} />
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <H1>Tiles Story</H1>
          <p>RELEASE 2024-06-01</p>
        </div>
      </Head>
      <DownloadRow>
        <a className='clean' href={downloadUrlGoogle}>
          <img alt='Ladda ned på Google Play' height={78} src={badgeUrlGoogle} />
        </a>
        <a className='clean' href={downloadUrlApple}>
          <img alt='Hämta i App Store' height={60} src={appstore} />
        </a>
      </DownloadRow>
      <p>Tiles Story är ett ordspel utvecklat av Reactive Pixels. Spelet passar alla åldrar och går ut på att bygga ord av bokstäver på ett bräde.</p>
      <p>Klara spelets utmanande kapitel genom att bygga ord. Samla stjärnor i takt med dina framsteg, slå rekord och tävla mot vänner!</p>
      <ScreenShotsRow>
        <img src={dailyword} alt="Dagens ord"></img>
        <img src={lobby} alt="Lobby"></img>
        <img src={level5} alt="Nivå 5"></img>
      </ScreenShotsRow>
      <Links>
        <a href={void(0)} onClick={() => togglePage(Pages.TilesStoryPrivacy)}>Privacy policy</a>
        <a href={void(0)} onClick={() => togglePage(Pages.TilesStoryDataDeletion)}>Data deletion instructions</a>
      </Links>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  font-family: satoshi-regular;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  img {
    margin-right: 30px;
    border-radius: 10px;
  }
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;

  a {
    margin: 0 30px;
  }
`;

const H1 = styled.h1`
  font-family: gasoek-one;
  font-size: 72px;
  line-height: 72px;
  margin: 0;
  color: #fff;
  font-weight: 400;

  @media only screen and (max-width: 1020px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

const DownloadRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 0 60px 0;

  & > a:first-child {
    margin-right: 30px;
  }

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    margin: 20px 0 30px 0;

    & > a:first-child {
      margin-right: 0;
    }
  }
`

const ScreenShotsRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  img {
    max-width: 320px;
    margin: 0 10px;
  }

  @media only screen and (max-width: 1020px) {
    flex-direction: column;
  }
`

export default TilesStoryLandingPage;
