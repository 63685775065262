import React, { useEffect, useRef, useState } from 'react';
import { Pages } from '../../App';
import logo from '../../ReactivePixels_light-text.png';
import Menu from '../Menu/Menu';
import { HeaderLogo, HeaderLogoLink, HeaderWrapper } from './Header.style';

type HeaderProps = {
  togglePage?: (page: Pages) => void;
}
const Header = (props: HeaderProps) => {
  const headerContainer = useRef<HTMLElement>(null);
  const [headerClass, setHeaderClass] = useState<string>()

  useEffect(() => {
    const handleScroll = () => {
      const position = window?.pageYOffset || document?.documentElement?.scrollTop
      setHeaderClass(position > 30 ? 'scrolled' : '')
    }
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    }
  }, [])

  return (
    <HeaderWrapper className={`header ${headerClass}`} ref={headerContainer}>
      <HeaderLogoLink
        onClick={() => props.togglePage?.(Pages.Start)}
        onKeyDown={(e) => e.key === 'Enter' && props.togglePage?.(Pages.Start)}
        role="link"
        tabIndex={0}
      >
        <HeaderLogo src={logo} width="546" height="224" className="header-logo" alt="Reactive Pixels logotyp" />
      </HeaderLogoLink>
      <Menu togglePage={props.togglePage} />
    </HeaderWrapper>
  );
}

export default Header;
