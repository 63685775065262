import { SeparatorBox, SeparatorLine, SeparatorWrapper } from "./Separator.style"

const Separator = () => {
  return <SeparatorWrapper>
    <SeparatorLine />
    <SeparatorBox />
    <SeparatorLine />
  </SeparatorWrapper>
}

export default Separator