import React from 'react';
import { GrLinkedin } from "react-icons/gr";
import { TfiEmail } from "react-icons/tfi";
import { FooterContact, FooterCopyRight, FooterSocial, FooterWrapper } from './Footer.style';

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterCopyRight>© 2023 Reactive Pixels AB</FooterCopyRight>
      <FooterSocial>
        <a href='https://www.linkedin.com/company/reactive-pixels-ab/'>
          <GrLinkedin size={40} color="#ffffff" aria-label='Besök Reactive Pixels på LinkedIn' />
        </a>
      </FooterSocial>
      <FooterContact>
        <a href='mailto:info@reactivepixels.se' aria-label='Skicka e-post till Reactive Pixels'><TfiEmail size={20} color="#ffffff"/> info@reactivepixels.se</a>
      </FooterContact>
    </FooterWrapper>
  );
}

export default Footer;
