import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './Stars.css';

enum ScreenSize {
  ExtraSmall = 'ExtraSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  ExtraLarge = 'ExtraLarge'
}

const getScreenSizeFromWindow = (windowWidth: number) => {
  if (windowWidth <= 450) {
    return ScreenSize.ExtraSmall
  } else if (windowWidth > 450 && windowWidth <= 600) {
    return ScreenSize.Small
  } else if (windowWidth > 600 && windowWidth <= 900) {
    return ScreenSize.Medium
  } else if (windowWidth > 900 && windowWidth <= 1600) {
    return ScreenSize.Large
  } else if (windowWidth > 1600) {
    return ScreenSize.ExtraLarge
  }
}

const Stars = () => {
  const starsContainer = useRef<any>(null);
  const currentScreenSize = useRef<any>(getScreenSizeFromWindow(window?.innerWidth));
  const location = useLocation();

  const addStars = () => {
    if (!starsContainer || !starsContainer.current) return
    document.querySelectorAll('.stars .star').forEach(e => e.remove());
    const screen = currentScreenSize.current
    const numberOfStars = (screen === ScreenSize.ExtraLarge || screen === ScreenSize.Large) ? 150 : 120
    const starsContainerHeight = starsContainer.current.clientHeight || 0
    let i = 0

    while (i < numberOfStars) {
      let star: HTMLElement = document.createElement("i")
      let posX = Math.floor(Math.random() * 100) // %
      let posY = Math.floor(Math.random() * (starsContainerHeight - 10)) // px
      let size = Math.ceil(Math.random() * 3) // px
      let opacity = Math.random()
      star.className = `star`
      star.style.left = `${posX}%`
      star.style.top = `${posY}px`
      star.style.height = `${size}px`
      star.style.width = `${size}px`
      star.style.opacity = `${opacity}`
      starsContainer.current.appendChild(star)
      i++
    }
    document.querySelectorAll('.stars').forEach(e => e.classList.add('visible'));
  }

  useEffect(() => {
    addStars()
  }, [location])

  useEffect(() => {
    const handleResize = () => {
      // const height = window.innerHeight
      const width = window.innerWidth
      let newScreenSize = getScreenSizeFromWindow(width)

      if (currentScreenSize.current !== newScreenSize) {
        currentScreenSize.current = newScreenSize
        addStars()
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="stars" ref={starsContainer}>
    </div>
  );
}

export default Stars;
