import React from 'react';
import styled from 'styled-components';
import reactLogo from '../../assets/react_logo.png';
import reduxLogo from '../../assets/redux_logo.png';
import vueLogo from '../../assets/vue_logo.png';
import htmlLogo from '../../assets/html_logo.png';
import jsLogo from '../../assets/js_logo.png';
import cssLogo from '../../assets/css_logo.png';
import tsLogo from '../../assets/typescript_logo.png';
import gatsbyLogo from '../../assets/gatsby_logo.png';
import sassLogo from '../../assets/sass_logo.png';
import graphQLLogo from '../../assets/graphql_logo.png';
import styledComponentsLogo from '../../assets/styled_components_logo.png';
import Separator from '../../components/Separator/Separator';

const servicesData = [
  { img: reactLogo, text: 'React' },
  { img: vueLogo, text: 'Vue' },
  { img: tsLogo, text: 'TypeScript' },
  { img: reduxLogo, text: 'Redux' },
  { img: jsLogo, text: 'JavaScript' },
  { img: htmlLogo, text: 'HTML' },
  { img: cssLogo, text: 'CSS' },
  { img: styledComponentsLogo, text: 'Styled Components' },
  { img: sassLogo, text: 'Sass' },
  { img: gatsbyLogo, text: 'Gatsby' },
  { img: graphQLLogo, text: 'GraphQL' },
]

const Services = () => {
  return (
    <ServicesWrapper>
      <h1>TJÄNSTER</h1>
      <p>Reactive Pixels är ett enmansföretag med mig, Emil, som konsult-resurs inom främst frontendutveckling.</p>
      <Separator />
      <Text>
        <h2>ERFARENHET</h2>
        <p>Jag har lång erfarenhet (8år+) och jobbat med en mängd olika tekniker. Oftast har jag varit del av ett utvecklingsteam som frontendutvecklare.<br/>Områden jag jobbat inom är exempelvis e-handel, e-tjänster, publika webbsidor, administrationsgränssnitt, CMS-verktyg, webbapplikationer etc.<br/>Med uppdrag både inom privat och offentlig sektor.</p>
        <Separator />
        <h2>TEKNISK KOMPETENS</h2>
        <p>Tekniska expertområden är framförallt React och Vue, men en längre lista över främsta kompetenser ses nedan.</p>
      </Text>
      <ServicesList>
        {servicesData.map(service => <ServiceBadge key={service.text} data={service} />)}
      </ServicesList>
    </ServicesWrapper>
  );
}

const ServiceBadge = ({ data }: any) => {
  return <ServiceBadgeWrapper>
    <img src={data.img} alt={data.text}></img>
    <span>{data.text}</span>
  </ServiceBadgeWrapper>
}

const ServiceBadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
  max-width: 80px;
  height: 112px;
  padding: 5px;

  span {
    display: block;
    margin-top: auto;
    font-weight: bold;
    font-size: 14px;

    @media only screen and (max-width: 900px) {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 900px) {
    max-width: 70px;
    height: 100px;
  }
`



const ServicesWrapper = styled.div`
  position: relative;
  text-align: center;
  p {
    max-width: 740px;
    margin: 10px auto;
  }
`

const ServicesList = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 740px;
  margin: 40px auto;
  flex-wrap: wrap;
`

const Text = styled.div`
  max-width: 740px;
  margin: 0 auto;
  text-align: left;

  h2 {
    margin-bottom: 0;
  }
`

export default Services;
