import React, { useContext } from 'react';
import styled from 'styled-components';
import playstore from '../../assets/tiles-story/playstore-icon.png';
import { Pages } from '../../App';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';

const TilesStoryPrivacyPage = () => {
  const navigate = useNavigate()
  const appContext = useContext(AppContext)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const togglePage = (newPage: Pages) => {
    appContext?.setActivePage(newPage)
    document.querySelectorAll('.stars').forEach(e => e.classList.remove('visible'));
    setTimeout(() => {
      navigate(newPage)
      scrollToTop()
    }, 300);
  }

  return (
    <Wrapper>
      <Head>
        <img alt='Tiles Story app-ikon' width={140} src={playstore} />
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
          <H1>Tiles Story - Privacy Policy</H1>
        </div>
      </Head>
      <Policy>
        <p>Reactive Pixels AB ("us", "we", or "our") operates the Tiles Story mobile app (hereinafter referred to as the "Service").</p>
        <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data. Our Privacy Policy is managed by the Data Controller for Tiles Story.</p>
        <p>We use your data to provide and improve the Service. How we use your data to improve the service is determined by the Data Processor for Tiles Story. By using the Service, you agree to the collection and use of information in accordance with this policy. Contact information for our Data Controller and Data Processor is found at the end of this policy.</p>
        
        <h2>Definitions</h2>
        <ul>
          <li><strong>Service</strong><br/>Service is the Tiles Story mobile app operated by Reactive Pixels AB</li>
          <li><strong>Personal Data</strong><br/>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</li>
          <li><strong>Usage Data</strong><br/>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a playing session).</li>
          <li><strong>Cookies</strong><br/>Cookies are small files stored on your device (computer or mobile device).</li>
        </ul>

        <h2>Information Collection and Use</h2>
        <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
        
        <h3>Types of Data Collected</h3>
        
        <h4>Personal Data</h4>
        <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
        <ul>
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Facebook/Google profile picture</li>
          <li>Cookies and Usage Data</li>
        </ul>

        <h4>Usage Data</h4>
        <p>We may also collect information how the Service is accessed and used ("Usage Data"). This Usage Data may include information such as your device's IP address, device type, device OS, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        
        <h4>Tracking & Cookies Data</h4>
        <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
        <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. These cookies are stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.</p>
        <p>You can instruct your device to refuse these cookies. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
        <p>Examples of Cookies we use:</p>
        <ul>
          <li><strong>Session Cookies. </strong>We use Session Cookies to operate our Service.</li>
          <li><strong>Preference Cookies. </strong>We use Preference Cookies to remember your preferences and various settings.</li>
          <li><strong>Security Cookies. </strong>We use Security Cookies for security purposes.</li>
        </ul>

        <h2>Use of Data</h2>
        <p>Tiles Story uses the collected data for various purposes:</p>
        <ul>
          <li>To provide and maintain the Service</li>
          <li>To notify you about changes to our Service</li>
          <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
          <li>To provide customer care and support</li>
          <li>To provide analysis or valuable information so that we can improve the Service</li>
          <li>To monitor the usage of the Service</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>

        
        <h2>Transfer of Data</h2>
        <p>Your information, including Personal Data, may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
        <p>If you are located outside Sweden and choose to provide information to us, please note that we transfer the data, including Personal Data, to Sweden and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
        <p>Tiles Story will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
        
        <h2>Disclosure of Data</h2>
        
        <h3>Legal Requirements</h3>
        <p>Tiles Story may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul>
          <li>To comply with a legal obligation</li>
          <li>To protect and defend the rights or property of Tiles Story</li>
          <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
          <li>To protect the personal safety of users of the Service or the public</li>
          <li>To protect against legal liability</li>
        </ul>
        
        <h2>Security of Data</h2>
        <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
        
        <h2>Your rights (GDPR)</h2>
        <ol>
          <li>The right to be informed; This means anyone processing your personal data must make clear what they are processing, why, and who else the data may be passed to.</li>
          <li>The right of access; this is your right to see what data is held about you by a Data Controller.</li>
          <li>The right to rectification; the right to have your data corrected or amended if what is held is incorrect in some way.</li>
          <li>The right to erasure; under certain circumstances you can ask for your personal data to be deleted. This is also called "the Right to be Forgotten". This would apply if the personal data is no longer required for the purposes it was collected for, or your consent for the processing of that data has been withdrawn, or the personal data has been unlawfully processed.</li>
          <li>The right to restrict processing; this gives the Data Subject the right to ask for a temporary halt to processing of personal data, such as in the case where a dispute or legal case has to be concluded, or the data is being corrected.</li>
          <li>The right to data portability; a Data Subject has the right to ask for any data supplied directly to the Data Controller by him or her, to be provided in a structured, commonly used, and machine-readable format.</li>
          <li>The right to object; the Data Subject has the right to object to further processing of their data which is inconsistent with the primary purpose for which it was collected, including profiling, automation, and direct marketing.</li>
          <li>Rights in relation to automated decision making and profiling; Data Subjects have the right not to be subject to a decision based solely on automated processing.</li>
        </ol>
        <p>If you have any questions regarding your data, please contact us (contact info at the end) and we will try our best to help you.</p>
        <p><a href={void(0)} onClick={() => togglePage(Pages.TilesStoryDataDeletion)}>Data deletion instructions</a></p>

        <h2>Service Providers</h2>
        <p>We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
        <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
        
        <h2>Links to Other Sites</h2>
        <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
        <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services</p>
        
        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us directly by email on info@reactivepixels.se</p>
      </Policy>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  font-family: satoshi-regular;
`

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  img {
    margin-right: 30px;
    border-radius: 10px;
  }
`

const H1 = styled.h1`
  font-family: gasoek-one;
  font-size: 56px;
  line-height: 56px;
  margin: 0;
  color: #fff;
  font-weight: 400;
  max-width: 700px;

  @media only screen and (max-width: 1020px) {
    font-size: 48px;
    line-height: 48px;
  }
`;

const Policy = styled.div`
  margin: 40px auto 60px auto;
  max-width: 1000px;

  p,
  li {
    text-align: left;
    color: #999999;
  }

  h2 {
    margin-top: 60px;
  }
`

export default TilesStoryPrivacyPage;
