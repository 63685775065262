import React, { useState, useEffect } from 'react'
import { TfiClose } from 'react-icons/tfi'
import { useNavigate } from 'react-router-dom'
import { Pages } from '../../App'
import { BannerContent, BannerWrapper, BannerText, BannerButton, PolicyLink } from './CookieBanner.style'

const CookieBanner = () => {
  const navigate = useNavigate()
  const [showCookieBanner, setShowCookieBanner] = useState<boolean>(false)
  const [clazz, setClazz] = useState<string>()

  const hasAlreadyConsent = () => {
    let cookies = document?.cookie
    const decoded = decodeURIComponent(cookies)
    return !!(decoded?.indexOf('consent=true') !== -1)
  }

  const setConsent = () => {
    let expiryDate = new Date()
    expiryDate.setFullYear(expiryDate.getFullYear() + 1)
    document.cookie = `consent=true;expires=${expiryDate.toUTCString()}`
  }

  const onNavigateToCookiePolicy = () => {
    navigate(Pages.Cookies)
  }

  const onCloseCookieBanner = () => {
    setConsent()
    setClazz('hidden')
    setTimeout(() => {
      setShowCookieBanner(false)
    }, 300)
  }

  useEffect(() => {
    if (!hasAlreadyConsent()) {
      setClazz('visible')
      setTimeout(() => {
        setShowCookieBanner(true)
      }, 300)
    }
  }, [])

  return (
    <BannerWrapper className={clazz || ''}>
      <BannerContent>
        <BannerText>
          <div>
            <span>Vi använder cookies 🍪</span>
            <PolicyLink
              onClick={onNavigateToCookiePolicy}
              onKeyDown={(e) => e.key === 'Enter' && onNavigateToCookiePolicy()}
              role="link"
              tabIndex={0}
            >Läs mer</PolicyLink>
          </div>
          <div>
            <span>Genom att använda webbplatsen godkänner du dessa cookies.</span>
          </div>
        </BannerText>
        <BannerButton onClick={onCloseCookieBanner} tabIndex={!showCookieBanner ? -1 : 0}>
          <TfiClose size={20} color="#ffffff"/>
          <span className='sr-only'>Stäng och acceptera</span>
        </BannerButton>
      </BannerContent> 
    </BannerWrapper>
  );
}

export default CookieBanner;
