import React, { useEffect, useState, AnimationEvent, useContext } from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Pages } from '../../App';
import { AppContext } from '../../context/AppContext';

export type MainContentProps = {}

const MainContent = (props: MainContentProps) => {
  const appContext = useContext(AppContext)
  const [classes, setClasses] = useState<string>('')
  const [visiblePage, setVisiblePage] = useState<Pages | undefined>(appContext?.activePage)

  const onAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    const animationName = e.animationName
    if (animationName === 'fadeOut') {
      setVisiblePage(appContext?.activePage)
      setClasses('fade-in')
    }
  }

  useEffect(() => {
    setClasses('fade-out')
  }, [appContext?.activePage])

  return (
    <Main>
      <Content onAnimationEnd={onAnimationEnd} className={classes}>
        <ContentInner>
          <Outlet />
          <TopLeftGraphics />
          <TopRightGraphics />
          <BottomLeftGraphics />
          <BottomRightGraphics />
        </ContentInner>
      </Content>
    </Main>
  );
}

export const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  background-color: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(3px);
`;

const GraphicBaseStyle = `
  position: absolute;
  width: 15px;
  height: 15px;
  display: block;
`;

export const TopLeftGraphics = styled.div`
  ${GraphicBaseStyle}
  top: 10px;
  left: 10px;
  border-left: 1px solid white;
  border-top: 1px solid white;
`;
export const TopRightGraphics = styled.div`
  ${GraphicBaseStyle}
  top: 10px;
  right: 10px;
  border-right: 1px solid white;
  border-top: 1px solid white;
`;
export const BottomRightGraphics = styled.div`
  ${GraphicBaseStyle}
  bottom: 10px;
  right: 10px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
`;
export const BottomLeftGraphics = styled.div`
  ${GraphicBaseStyle}
  bottom: 10px;
  left: 10px;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  padding: 40px 0;

  @media only screen and (max-width: 900px) {
    padding: 0;
  }

  &.fade-in {
    animation: fadeIn 0.3s;
    animation-fill-mode: forwards;
  }
  &.fade-out {
    animation: fadeOut 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scaleY(0);
    }
    100% {
      opacity: 1;
      transform: scaleY(1);
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: scaleY(1);
    }
    100% {
      opacity: 0;
      transform: scaleY(0);
    }
  }
`;

const ContentInner = styled.div`
  position: relative;
  width: 60%;

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 20px 20px 40px 20px;
  }
`;

export default MainContent;
